import Swal from 'sweetalert2'
import i18n from '@/libs/i18n'
import { useToaster } from '@/composables/useToaster'

const _toastDelay = (response, method, successTitle, forceToast) => {
  if (response.config.method === 'post' && !forceToast) {
    Swal.fire({
      title: i18n.global.t('request.status.success', {
        msg: successTitle || method,
      }),
      text: response.config.message || '',
      icon: 'success',
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      buttonsStyling: false,
    })
  } else {
    const title = i18n.global.t('request.status.success', {
      msg: successTitle || method,
    })
    const { toaster } = useToaster()
    toaster(title, 'success', 'CheckCircleIcon', response.config.message || '')
  }
}

const successHandler = response => {
  if (response.status >= 200 && response.status < 300) {
    let dontAlertUser = response.config.dontAlertUser || false
    let method = ''
    const forceToast = response.config.forceToast || false
    const successTitle = response.config.successTitle || null
    const action = response.config.action || response.config.method
    switch (action) {
      case 'post':
        method = i18n.global.t('request.action.add')
        break
      case 'patch':
        method = i18n.global.t('request.action.update')
        break
      case 'delete':
        method = i18n.global.t('request.action.delete')
        break
      default:
        dontAlertUser = true
        break
    }
    if (!dontAlertUser) {
      setTimeout(_toastDelay(response, method, successTitle, forceToast), 250)
    }
    dontAlertUser = false
  }
  return response
}

export default successHandler
